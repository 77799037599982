import { useState } from "react";
import { useForm } from "react-hook-form";
import { Input } from "./common/Input";
import PrimaryHeading from "./common/PrimaryHeading";
import circle from "../assets/images/png/hero-circle.png";

export const MakeSwitch = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [currentStep, setCurrentStep] = useState(1);
  const [showPopup, setShowPopup] = useState(false);

  const commonStepsClass =
    "size-7 sm:size-[55px] bg-white rounded-full border border-dotted flex items-center justify-center font-jost text-sm sm:text-2xl font-semibold";
  const steps = [1, 2, 3];

  const onSubmitStep1 = (data) => {
    if (currentStep === 1 && !errors.name && !errors.email && !errors.phone) {
      setCurrentStep(2);
    }
  };

  const onSubmitStep2 = (data) => {
    if (currentStep === 2) {
      setCurrentStep(3);
    }
  };

  const handleStepClick = (step) => {
    if (step < currentStep) {
      setCurrentStep(step);
    }
  };

  const handleCheckboxChange = () => {
    setShowPopup(true);
  };

  const getStepClass = (step) => {
    if (step < currentStep) {
      return "!border-tron-blue cursor-pointer";
    } else if (step === currentStep) {
      return "!bg-tron-blue !border-tron-blue";
    } else {
      return "border-cyan-blue text-black";
    }
  };

  return (
    <div className="pt-[75px] md:pt-[173px] relative">
      <img
        className="animate-spin-slow xl:size-[161px] md:size-[80px] size-[70px] absolute right-10 lg:top-[220px] top-20 pointer-events-none md:block hidden"
        src={circle}
        alt="circle"
      />
      <div className="xl:max-w-[1180px] px-5 mx-auto container">
        <PrimaryHeading
          className="text-center mb-2.5 [text-shadow:0px_0px_7.2px_#00000030] sm:px-0 px-4"
          Children="Ready to Make the Switch?"
        />
        <p className="max-w-[664px] sm:px-0 px-4 text-center mx-auto font-normal text-base leading-lh_150 text-cyan-blue text-opacity-70 font-poppins">
          It's time to stop overpaying for your cell phone and internet
          services. Fill out the form below to get started. We'll guide you
          through the simple process of switching to a much more cost-effective
          carrier.
        </p>
        <div className="py-8 sm:py-12 lg:py-14 xl:py-20 xl:mt-1 xl:mb-4 max-w-[611px] w-full flex items-center justify-between relative mx-auto">
          <span
            className={`border-t border-dotted border-cyan-blue absolute top-1/2 left-0 w-1/2 -z-10 ${
              currentStep > 1 && "!border-tron-blue"
            }`}
          ></span>
          <span
            className={`border-t border-dotted border-cyan-blue absolute top-1/2 w-1/2 right-0 -z-10 ${
              currentStep > 2 && "!border-tron-blue"
            }`}
          ></span>
          {steps.map((step) => (
            <span
              key={step}
              onClick={() => handleStepClick(step)}
              className={`${commonStepsClass} ${getStepClass(step)}`}
            >
              {step}
            </span>
          ))}
        </div>

        {currentStep === 1 && (
          <>
            <p className="sm:font-inter font-jost text-cyan-blue font-semibold text-2xl drop-shadow-[0_0_7.2px_0_#0000001F] max-sm:mt-6">
              Fill Out Your Information
            </p>
            <form
              className="md:mt-9 mt-3"
              onSubmit={handleSubmit(onSubmitStep1)}
            >
              <div className="grid col-span-1 sm:grid-cols-3 sm:gap-6 w-full">
                <Input
                  name="name"
                  {...register("name", {
                    required: "Name is required",
                  })}
                  error={errors.name?.message}
                />
                <Input
                  name="email"
                  type="email"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i,
                      message: "Enter a valid email address",
                    },
                  })}
                  error={errors.email?.message}
                />
                <Input
                  name="phone"
                  type="number"
                  {...register("phone", {
                    required: "Phone number is required",
                    minLength: {
                      value: 7,
                      message: "Phone number must be at least 7 digits",
                    },
                    maxLength: {
                      value: 15,
                      message: "Phone number must be no more than 15 digits",
                    },
                  })}
                  error={errors.phone?.message}
                />
                <textarea
                  name="message"
                  {...register("message")}
                  className="border-b outline-none max-sm:min-h-36 w-full border-b-gray py-[22px] lg:py-2 font-poppins text-base font-normal placeholder:text-base placeholder:text-cyan-blue sm:col-span-3 resize-none sm:mt-5"
                  placeholder="Message"
                ></textarea>
              </div>
              <div className="mt-6">
                <label className="flex items-start space-x-3">
                  <input
                    type="checkbox"
                    className="w-4 h-4 mt-1"
                    onChange={handleCheckboxChange}
                  />
                  <span className="text-cyan-blue text-sm font-poppins">
                    I agree to the{" "}
                    <a
                      href="/tnc"
                      className="hover:text-tron-blue underline"
                      //target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms and Conditions
                    </a>
                    ,{" "}
                    <a
                      href="/privacy"
                      className="hover:text-tron-blue underline"
                      //target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy
                    </a>
                    , and communications.
                  </span>
                </label>
              </div>
              <button
                aria-label="save"
                type="submit"
                className="md:mt-16 mt-11 font-semibold sm:max-w-[128px] w-full text-base leading-lh_145 font-jost text-navy-blue bg-tron-blue rounded-full px-9 py-[13.6px] border border-transparent border-solid hover:border-cyan-blue hover:bg-transparent transition-all duration-300"
              >
                Save
              </button>
            </form>
          </>
        )}

        {currentStep === 2 && (
          <>
            <form
              className="flex flex-col items-center justify-center max-sm:mt-6"
              onSubmit={handleSubmit(onSubmitStep2)}
            >
              <p className=" font-jost font-semibold text-2xl text-cyan-blue drop-shadow-[0_0_7.2px_0_#0000001F] mb-2">
                Book a Meeting
              </p>
              <p className="max-w-[1013px] text-center mx-auto font-normal text-base leading-lh_150 text-cyan-blue text-opacity-70 font-poppins">
                Click the link to schedule a 5 to 15-minute meeting with one of
                our experts. We'll discuss your needs, evaluate your current
                bill, and show you how much you can save. Thank you for
                considering our services. Together, we can cut your
                telecommunication costs and keep more money in your pocket.
              </p>
              <button
                aria-label="click-here"
                className="mt-10 font-semibold max-w-[128px] w-full text-nowrap text-base leading-lh_145 font-jost text-navy-blue bg-tron-blue rounded-full px-4 py-[13.6px] border border-transparent border-solid hover:border-cyan-blue hover:bg-transparent transition-all duration-300"
              >
                Click here
              </button>
            </form>
          </>
        )}

        {currentStep === 3 && (
          <>
            <p className="font-jost font-semibold text-2xl text-cyan-blue drop-shadow-[0_0_7.2px_0_#0000001F] text-center">
              Get Connected With Us On{" "}
              <a
                aria-label="getbetterbills"
                className="text-green-500 hover:text-tron-blue duration-300"
                target="_blank"
                rel="noopener noreferrer"
                // href="https://api.whatsapp.com/send/?phone=%2B16467121079&text=Hello, I need assistance! &type=phone_number&app_absent=0"
                href="https://api.whatsapp.com/send/?phone=%2B9999999999&text=Hello, I need assistance! &type=phone_number&app_absent=0"
              >
                WhatsApp
              </a>
            </p>
          </>
        )}

        {showPopup && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-6 rounded-md shadow-lg max-w-lg">
              <p className="text-sm text-black font-poppins mb-4">
                By checking this box, I agree to receive communications, including email, calls, and text messages from Getbetterbills.com and it's affiliates to further follow up to discuss the promotions and information posted on this website, along with future updates. Reply to any messages with STOP at any time to stop receiving messages and request for help by replying HELP. The frequency of messages varies. Message and data rates may apply.
              </p>
              <button
                className="bg-tron-blue text-white px-4 py-2 rounded-md"
                onClick={() => setShowPopup(false)}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
